<template lang="pug">
    section#linhaIndustrias
        .wrapper
            h3 {{contents.content.title}}
            ul
                li(v-for="item in contents.content.years")
                    span.year {{ item.year }}
                    .content
                        span(v-for="textos in item.texts").text {{ textos }}
</template>
<script>
import { props } from '@/mixins/component'

export default {
    name: "section-linha-industrias",
    props,
}
</script>

<style lang="stylus" scoped src="./LinhaIndustrias.styl"></style>